@charset "utf-8";
/* CSS Document */
/* CSS Reset begin */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* CSS Reset end */
textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
  background-color: #FFF;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0.73em 0.73em 0.73em;
  font-size: 1.2em;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #4E4848;
}
p,
table,
figure {
  margin: 0 0.73em 0.73em 1em;
  font-size: 1.2em;
  /*line-height: 1.4;*/
}
figure {
  padding: 1em;
  background-color: #ededed;
  display: inline-block;
}
figcaption {
  font-size: 1.2em;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 0.72em;
  color: #4E4848;
  display: block;
  margin-top: 1em;
}
main {
  padding-top: 1.4em;
}
main p,
main figcaption {
  max-width: 40em;
}
main img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}
main .lessImportant {
  font-size: 0.72em;
}
.Peptide {
  font-size: 1.8em;
  line-height: 2;
  color: #000;
  max-width: none;
}
sup,
sub {
  font-size: 0.7em;
  padding: 0.1em;
  font-weight: bolder;
  position: relative;
}
sup {
  top: -0.4em;
}
sub {
  top: 0.3em;
}
footer {
  text-align: center;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.72em;
  margin: 4em 0 1em 0;
}
a:visited,
a:link {
  text-decoration: none;
}
nav {
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
main ul {
  list-style-type: disc;
  margin-left: 4em;
  font-size: 1.2em;
  max-width: 36em;
}
main li {
  margin-bottom: 0.5em;
}
noscript {
  font-size: 1.2em;
  padding: 1em;
  background-color: #FFFF66;
  color: #800000;
  line-height: 3;
  font-weight: bold;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
/* Menu begin */
#main-menu {
  width: 14.4em;
  float: left;
  background-color: #0151A4;
  position: relative;
  z-index: 100;
}
#main-menu a:link,
#main-menu a:visited {
  /*text-decoration: none;*/
  color: #FFF;
}
#main-menu a:hover {
  color: #B8DAFE;
}
#main-menu ul {
  line-height: 2;
  font-weight: bold;
  font-size: 1.2em;
  text-transform: uppercase;
  padding: 1em 0;
}
#main-menu li {
  display: block;
  padding-left: 1.2em;
}
/* Menu end */
/* Back-to-top link begin */
.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 4em;
  left: 7em;
  text-decoration: none;
  color: #000;
  background-color: rgba(1, 81, 164, 0.4);
  font-size: 1.2em;
  font-weight: bold;
  padding: 1em 1.3em;
  display: none;
  border-radius: 4em;
  transition: background .45s linear;
  border: 0.2em solid rgba(255, 255, 255, 0.8);
  z-index: 150;
}
.back-to-top:hover {
  background-color: rgba(135, 135, 135, 0.5);
}
.back-to-top {
  color: #000;
  /*font-size:@font-size-large*/
}
/* Back-to-top link end */
main {
  margin-left: 14.4em;
  /*background-color: #B7CFEC;*/
}
.aa,
.pepMods {
  padding: 0.2em 0.2em 0.3em;
  margin-left: 0.03em;
  margin-right: 0.05em;
  border-radius: 0.5em;
  white-space: nowrap;
}
.protein {
  font-family: Consolas, monaco, monospace;
  margin-left: 0.8em;
}
.branch {
  white-space: normal;
  background-color: #eaeaea;
}
.branch .branch {
  background-color: #f7f7f7;
}
.branch .branch .branch {
  background-color: #ffffff;
}
.AADefault,
.ProteinFragment {
  background-color: #ddd;
}
.ProteinOverlap {
  background-color: #c8d4a2;
}
.AANonpolar {
  background-color: #cae4c9;
}
.AAPolar {
  background-color: #f8d889;
}
.AAAcidic {
  background-color: #ffc9ea;
}
.AABasic {
  background-color: #c7e0f9;
}
.PepTerminal,
.pepMods {
  background-color: #efefef;
}
.error {
  color: red;
}
#logo {
  position: fixed;
  top: 50%;
  transform: translateY(-30%);
  width: 14.4em;
  left: 0em;
  z-index: 50;
}
#logo img {
  width: 12.4em;
  margin-left: 1em;
}
.control-label {
  width: 29%;
  margin-right: 1%;
  max-width: 12em;
  line-height: 2em;
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
  vertical-align: top;
}
.form-control {
  width: 50%;
  max-width: 40em;
  display: inline-block;
  font-size: 1.2em;
  padding-left: 0.5em;
  color: #555;
  border: 0.1em solid #ccc;
  border-radius: 0.4em;
  box-shadow: inset 0em 0.1em 0.1em rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
input.form-control {
  height: 2em;
}
textarea.form-control {
  height: 6em;
}
.field-validation-valid {
  position: relative;
}
.field-validation-error {
  /*width: 19%;*/
  margin-left: 1%;
  line-height: 2em;
  display: inline-block;
  font-size: 1.2em;
}
.text-danger {
  color: red;
}
.btn {
  padding: 0.4em 0.5em 0.5em;
  display: inline-block;
  font-size: 1.2em;
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  text-align: center;
  white-space: nowrap;
  border: 0.1em solid;
  border-radius: 0.4em;
  background-color: #FFF;
  border-color: #CCC;
  transition-property: background;
  transition-duration: 0.3s;
  height: auto;
  line-height: 1em;
}
.btn:hover {
  background-color: #DDD;
  cursor: pointer;
}
.form-group {
  display: block;
  margin: 1em 0em;
}
/*.form-group:after {
    clear: both;
    content: "";
    display: table;
}*/
.form-vertical,
.form-horizontal {
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 2.4em;
}
.form-vertical .btn,
.form-horizontal .btn {
  margin-left: 12em;
  margin-left: calc(13em);
}
.form-vertical .control-label,
.form-vertical .form-control,
.form-vertical .field-validation-error {
  display: block;
  width: 90%;
  margin: 0.1em 5%;
  max-width: 40em;
  text-align: left;
  line-height: normal;
}
.form-vertical .btn {
  margin-left: 5%;
  margin-top: 1em;
}
.avatar-left {
  border-radius: 50%;
  float: left;
  margin: 0 1em 1em 1em;
  max-width: 100%;
}
.avatar-left-small {
  border-radius: 50%;
  float: left;
  margin: 0.1em 1em 0.3em 1em;
  max-width: 4em;
}
header {
  margin-bottom: 2em;
}
header h2,
header h3 {
  font-size: 0.72em;
  margin-left: 1.5em;
}
.width-limited {
  max-width: 40em;
  margin-bottom: 1em;
  margin-left: 2em;
}
.four-by-three-wrapper {
  position: relative;
  padding-bottom: 75%;
  /* 4:3 */
  height: 0;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.major-link-area {
  margin-bottom: 0.5em;
}
.major-link-area + h1,
h2,
h3 {
  margin-top: 0.8em;
}
.major-link {
  width: 20em;
  margin: 0 0 1em 1.5em;
  float: left;
  padding: 1em;
  border: 0.3em dashed #4E4848;
  border-radius: 1em;
}
.major-link-area:after {
  clear: both;
  content: "";
  display: table;
}
tr:nth-child(even),
.accented {
  background-color: rgba(0, 0, 0, 0.13);
}
th {
  padding-right: 0.3em;
  background-color: #FFF;
}
td {
  padding-right: 0.3em;
}
.posStates {
  background: linear-gradient(#FFF, #fff5f5);
}
.negStates {
  background: linear-gradient(#FFF, #cee6ff);
}
.legendNarrow {
  font-size: 0.72em;
  margin-top: -0.7em;
}
.advert:before {
  font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
  content: "Advertisement:";
  display: block;
  clear: both;
  border-bottom-style: dotted;
  border-bottom-width: medium;
  margin-bottom: 0.9em;
}
.advert {
  max-width: 40em;
  border-bottom-style: dotted;
  border-bottom-width: medium;
  padding-bottom: 0.8em;
  margin: 0 auto 1em 1.4em;
  z-index: 555;
  position: relative;
}
.fixedPoint {
  text-align: right;
}
@media screen and (max-width: 40.32em), print {
  figure {
    padding: 0.5em;
    max-width: 97%;
    margin: 0.5em auto 0.5em 2%;
  }
  figcaption {
    margin-top: 0.5em;
  }
  #main-menu {
    float: none;
    width: auto;
  }
  main {
    margin-left: 0;
  }
  footer {
    margin: 2em 0 0.6em 0;
  }
  #main-menu {
    text-align: center;
  }
  #main-menu li {
    display: inline;
    padding: 0 1em;
  }
  p,
  table {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  .advert {
    margin-left: auto;
  }
  table {
    overflow-x: auto;
    display: block;
  }
  .back-to-top {
    left: auto;
    right: 1em;
  }
  #logo {
    position: static;
    top: auto;
    transform: none;
    width: auto;
    text-align: center;
  }
  #logo img {
    width: 80%;
    margin-left: auto;
    max-width: 12.4em;
  }
  .form-group {
    margin: 0.5em 0em;
  }
  .control-label,
  .form-control,
  .field-validation-error {
    display: block;
    width: 98%;
    margin: auto 1%;
    max-width: none;
    text-align: left;
    line-height: normal;
  }
  .form-vertical,
  .form-horizontal {
    margin-bottom: 1.8em;
  }
  .form-vertical .btn,
  .form-horizontal .btn {
    margin-left: 5%;
  }
  .avatar-left {
    float: none;
    margin: 0.27em 0;
  }
  .width-limited {
    max-width: 40em;
    margin-bottom: 0.6em;
    margin-left: 0;
  }
  .major-link-area {
    margin-left: 0.3em;
    margin-right: 0.3em;
  }
  .major-link {
    width: 100%;
    margin: 0 0 0.3em 0;
    float: none;
    padding: 0.3em;
    border: 0.3em dashed #4E4848;
    border-radius: 1em;
  }
  .protein {
    margin-left: 2%;
    max-width: 98%;
    overflow-x: scroll;
  }
}
/*body { margin: 30px; }*/
/* Add some margin to the body to add space and 
see where your offending elements cross the line */
/*body * { border: 1px solid red; }*/
/* add a border to all other elements on the page */
