@charset "utf-8";
/* CSS Document */

@body-bg: #FFF;
@menu-bg: #0151A4;
@copy-color: #000;
@header-color: #4E4848;
@bg-accent-color: rgba(0,0,0,0.13);
@font-size-medium: 1.2em;
@font-size-large: (@font-size-medium * 1.5);
@font-size-small: (@font-size-medium * 0.6);
@menu-column-width: (@font-size-medium * 12);
@breakover-width: (@menu-column-width * 2.8);
@max-text-width: 40em;

/* CSS Reset begin */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, main, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* CSS Reset end */

textarea, input, button, select { font-family: inherit; font-size: inherit; }

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}


body {
	font-family: TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;
	background-color: @body-bg;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0 0.73em 0.73em 0.73em;
	font-size: @font-size-medium;
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: bold;
	color: @header-color;
}

p, table, figure {
	margin: 0 0.73em 0.73em 1em;
	font-size: @font-size-medium;
	/*line-height: 1.4;*/
}

figure {
	padding: 1em;
	background-color: darken(@body-bg, 7%);
	display: inline-block;
}

figcaption {
	font-size: @font-size-medium;
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-size: @font-size-small;
	color: @header-color;
	display: block;
	margin-top: 1em;
}

main {
	padding-top: 1.4em;
	p, figcaption {
		max-width: @max-text-width;
	}
	img {
		max-width: 100%;
		height:auto;
		display:inline-block;
	}
}

main .lessImportant {
	font-size: @font-size-small;
}

.Peptide {
	font-size: @font-size-large;
	line-height: 2;
	color: #000;
	max-width: none;
}

sup, sub {
	font-size: 0.7em;
	padding: 0.1em;
	font-weight: bolder;
    position: relative;
}

sup {
   top: -0.4em;
}
 
sub {
   top: 0.3em;
}

footer {
	text-align: center;
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: @font-size-small;
	margin: 4em 0 1em 0;
}

 a:visited, a:link/*, a:hover, a:active*/
{
    text-decoration: none;
}

nav {
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

main ul {
	list-style-type: disc;
	margin-left: 4em;
	font-size: @font-size-medium;
	max-width: (@max-text-width - 4em);	
}

main li {
	margin-bottom: 0.5em;
}

noscript {
	font-size: @font-size-medium;
	padding: 1em;
	background-color: #FFFF66;
	color: #800000;
	line-height: 3;
	font-weight: bold;
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Menu begin */
#main-menu {
	width: @menu-column-width;
	float: left;
	background-color: @menu-bg;
	position: relative;
    z-index: 100;
}

#main-menu a:link, #main-menu a:visited {
	/*text-decoration: none;*/
	color: #FFF;
}
#main-menu a:hover {
	color: #B8DAFE;
}

#main-menu ul {
	line-height: 2;
	font-weight: bold;
	font-size: @font-size-medium;
	text-transform: uppercase;
	padding: 1em 0;
}

#main-menu li {
	display: block;
	padding-left: @font-size-medium;
}

/* Menu end */

/* Back-to-top link begin */

.back-to-top {
	cursor:pointer;
	position:fixed;
	bottom:4em;
	left:7em;
	text-decoration:none;
	color:#000;
	background-color:fade(@menu-bg,40%);
	font-size:@font-size-medium;
	font-weight: bold;
	padding:1em 1.3em;
	display:none;
	border-radius:4em;
	transition:background .45s linear;
	border: 0.2em solid fade(contrast(@menu-bg),80%);
    z-index: 150;
}

.back-to-top:hover {
	background-color:rgba(135,135,135,.5)
}

.back-to-top  {
	color:#000;
	/*font-size:@font-size-large*/
}

/* Back-to-top link end */


main {
	margin-left: @menu-column-width;
	/*background-color: #B7CFEC;*/
}

.pc-output {
}

@AANonpolarColor: #cae4c9;
@AADefaultColor: #ddd;
@ServicePartColor: #efefef;

.aa, .pepMods {
	padding: 0.2em 0.2em 0.3em;
	margin-left: 0.03em;
	margin-right: 0.05em;
	border-radius: 0.5em;
    white-space: nowrap;
}

.protein {
	font-family: Consolas, monaco, monospace;
	margin-left: 0.8em;
}

.branch {
    white-space: normal;
	background-color:lighten(@AADefaultColor, 5%);
	.branch {
		background-color:lighten(@AADefaultColor, 10%);
		.branch {
			background-color:lighten(@AADefaultColor, 15%);
		}
	}
}


.AADefault, .ProteinFragment {
	background-color:@AADefaultColor;
}

.ProteinOverlap {
	background-color:#c8d4a2;
}


.AANonpolar {
	background-color:@AANonpolarColor;
}

.AAPolar {
	background-color:#f8d889;
}

.AAAcidic {
	background-color:#ffc9ea;
}

.AABasic {
	background-color:#c7e0f9;
}

.PepTerminal, .pepMods {
    background-color:@ServicePartColor;
}


.error {
    color: red;
}

#logo {
	position: fixed;
	top: 50%;
	transform: translateY(-30%);
	width: @menu-column-width;
	left:0em;
	z-index: 50;
}


#logo img {
	width: (@menu-column-width - 2em);
	margin-left: 1em;
}


.control-label {
	width: 29%;
	margin-right: 1%;
	max-width: 12em;
	line-height: 2em;
	display: inline-block;
	font-size: @font-size-medium;
	font-weight: bold;
	text-align: right;
	vertical-align:top;
}

.form-control {
	width: 50%;
	max-width: @max-text-width;
	display: inline-block;
	font-size: @font-size-medium;
	padding-left: 0.5em;
	color: #555;
	border: 0.1em solid #ccc;
	border-radius: 0.4em;
	box-shadow: inset 0em 0.1em 0.1em rgba(0,0,0,0.075);
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}


input.form-control {
	height: 2em;
}

textarea.form-control {
    height: 6em;
}

.field-validation-valid {
	position: relative;
}

.field-validation-error {
	/*width: 19%;*/
	margin-left: 1%;
	line-height: 2em;
	display: inline-block;
	font-size: @font-size-medium;
}

.text-danger {
	color: red;
}


.btn {
	padding: 0.4em 0.5em 0.5em;
    display: inline-block;
	font-size: @font-size-medium;
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: @copy-color;
    text-align: center;
    white-space: nowrap;
    border: 0.1em solid;
    border-radius: 0.4em;
	background-color: #FFF;
	border-color: #CCC;
	transition-property: background;
	transition-duration: 0.3s;
	height: auto;
	line-height: 1em;
}

.btn:hover {
	background-color: #DDD;
    cursor: pointer;
}


.form-group {
	display: block;
	margin: 1em 0em;
}

/*.form-group:after {
    clear: both;
    content: "";
    display: table;
}*/

.form-vertical, .form-horizontal{
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
	margin-bottom: 2.4em;
	.btn {
	margin-left: 12em;
	margin-left: calc(12em + 1%);
	}
}

.form-vertical
{
	.control-label, .form-control, .field-validation-error  {
		display: block;
		width: 90%;
		margin: 0.1em 5%;
		max-width: @max-text-width;
		text-align: left;
		line-height: normal;
	}

	.btn {
		margin-left: 5%;
		margin-top: 1em;
	}
}

.avatar-left {
	border-radius: 50%;
	float: left;
	margin: 0 1em 1em 1em;
	max-width: 100%;
}

.avatar-left-small {
	border-radius: 50%;
	float: left;
	margin: 0.1em 1em 0.3em 1em;
	max-width: 4em;
}

header {
	h2, h3 {
		font-size: @font-size-small;
		margin-left: 1.5em;
	}
	margin-bottom: 2em;
}

.width-limited {
	max-width: @max-text-width;
	margin-bottom: 1em;
	margin-left: 2em;
}

.four-by-three-wrapper {
	position: relative;
	padding-bottom: 75%; /* 4:3 */
	//padding-top: 25px;
	height: 0;
}

iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.major-link-area {
	margin-bottom: 0.5em;
}

.major-link-area + h1,h2,h3 {
	margin-top: 0.8em;
}

.major-link {
	width: 20em;
	margin: 0 0 1em 1.5em;
	float: left;
	padding: 1em;
	border: 0.3em dashed @header-color;
	border-radius: 1em;
}

.major-link-area:after {
    clear: both;
    content: "";
    display: table;
}

tr:nth-child(even), .accented {
    background-color: @bg-accent-color;
}

th {
	padding-right: 0.3em;
	background-color: #FFF;
}

td {
	padding-right: 0.3em;
}

.posStates {
	background: linear-gradient(#FFF, lighten(#F00, 48%));
}
.negStates {
	background: linear-gradient(#FFF, lighten(#0151A4, 58%));
}

.legendNarrow {
  font-size: @font-size-small;
  margin-top: -0.7em;
}

.advert:before {
	font-family: Gotham, "Helvetica Neue", Helvetica, Arial, sans-serif;
    content: "Advertisement:";
    display: block;
    clear: both;
	border-bottom-style: dotted;
	border-bottom-width: medium;
	margin-bottom: 0.9em;
}

.advert {
	max-width: @max-text-width;
	border-bottom-style: dotted;
	border-bottom-width: medium;
	padding-bottom: 0.8em;
	margin: 0 auto 1em 1.4em;
	z-index: 555;
	position: relative;
}

.fixedPoint {
	text-align: right;
}

@media screen and (max-width: @breakover-width), print {

	figure {
		padding: 0.5em;
		max-width: 97%;
		margin: 0.5em auto 0.5em 2%;
	}
	
	figcaption {
	margin-top: 0.5em;
	}


	#main-menu {
		float: none;
		width: auto;
	}

	main {
		margin-left: 0;
	}

	footer {
		margin: 2em 0 0.6em 0;
	}

	#main-menu {
		text-align: center;	
	}

	#main-menu li {
		display: inline;
		padding: 0 1em;
	}

	p, table {
		margin-left: 0.2em;
		margin-right: 0.2em;
	}
	
	.advert {
		margin-left: auto;
	}
	
	table {
		overflow-x: auto;
		display: block;
	}

	.back-to-top {
		left: auto;
		right: 1em;
	}

	#logo {
		position: static;
		top: auto;
		transform: none;
		width: auto;
		text-align: center;	
	}

	#logo img {
		width: 80%;
		margin-left: auto;
		max-width: (@menu-column-width - 2em);
	}

	.form-group {
		margin: 0.5em 0em;
	}	
	.control-label, .form-control, .field-validation-error  {
		display: block;
		width: 98%;
		margin: auto 1%;
		max-width: none;
		text-align: left;
		line-height: normal;
	}


	.form-vertical, .form-horizontal{
		margin-bottom: 1.8em;
		.btn {
			margin-left: 5%;	
		}	
	}
	
	.avatar-left{
		float: none;
		margin: 0.27em 0;
	}
	
	.width-limited {
		max-width: @max-text-width;
		margin-bottom: 0.6em;
		margin-left: 0;
	}

	.major-link-area {
		margin-left: 0.3em;
		margin-right: 0.3em;
	}

	.major-link {
		width: 100%;
		margin: 0 0 0.3em 0;
		float: none;
		padding: 0.3em;
		border: 0.3em dashed @header-color;
		border-radius: 1em;
	}

	.protein {
		margin-left: 2%;
		max-width: 98%;
		overflow-x: scroll;
	}	
}

/*body { margin: 30px; }*/
/* Add some margin to the body to add space and 
see where your offending elements cross the line */

/*body * { border: 1px solid red; }*/
/* add a border to all other elements on the page */
